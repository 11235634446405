import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import LearnMore from "../templates/learnMore";
import flexi_desks_1 from "../images/flexi_desks_1.jpg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Flexi Desks"
      />
      <LearnMore
        title={`Work flexibly in elegant open 
        work spaces`}
        cta={`Get in touch`}
        image={flexi_desks_1}
      >
        <div>
          <p className="text-xl">
            Flexi desking suits a range of business owners, employees and
            contractors who are seeking a professional presence to call home –
            on their own terms. We offer a range of packages from a simple
            drop-in through to dedicated days each month to ensure we are
            fitting your needs, and not the other way around. A flexi desk suits
            many varying individuals including those who travel frequently for
            work, and only visit Richmond on a case-by-case basis. Balance
            family with work and require a professional environment to focus on
            work away from those (loving) distractions.
          </p>
        </div>
        <div>
          <p className="text-xl pt-8 md:pt-0">
            Working at a flexible workspace in Richmond is about more than just
            a presence near the city. It is also about placing yourself
            mindfully in a space that encourages optimal performance and
            high-quality outcomes for you and your work. We have designed an
            intimate, boutique environment that inspires creativity and
            aspiration, with all the mod cons you could want from your ideal
            shared office space.{" "}
          </p>
        </div>
      </LearnMore>
    </Layout>
  );
}

export default IndexPage;
